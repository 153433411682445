import { BehaviorSubject, Observable, Subject, throwError as observableThrowError } from 'rxjs';
import { Injectable } from '@angular/core';
import { OrganisationService } from '@common/organisation.service';
import { ActivityReportModel } from './model/activity-report.model';
import { RemitReconciliationReportModel } from './reconciliation/remit-reconciliation-report.model';
import { HttpErrorResponse, HttpParams, HttpResponse } from '@angular/common/http';
import { OrganisationGroupModel } from '@common/organisation-group.model';
import { ApiRequestService } from '@common/api-request.service';
import { DateRangeFilter } from '@common/shared/shared.model';
import { Message } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { PresetValues } from './remit/remit-reconciliation-report-models';

@Injectable()
export class RemitReportService {

  remitActivityReport = new BehaviorSubject<ActivityReportModel[]>(null);
  elcomActivityReport = new BehaviorSubject<ActivityReportModel[]>(null);
  remitReconciliationReport = new BehaviorSubject<RemitReconciliationReportModel[]>(null);

  private message = new BehaviorSubject<Message>(null);
  private presetValues = new BehaviorSubject<PresetValues>({ uploadPanelVisible: false, rerunAllowed: false });

  activityReportApi: string = '/api/err/remit/report/activity/';
  remitReconciliationReportApi: string = '/api/err/remit/report/reconciliation/';

  orgGroup: OrganisationGroupModel;

  uploading: boolean = false;

  constructor(private orgService: OrganisationService, private apiRequestService: ApiRequestService) {
    orgService.getOrganisationGroup().subscribe(orgGroup => {
      this.orgGroup = orgGroup;
    });

  }

  getRemitActivityReport(): Observable<ActivityReportModel[]> {
    return this.remitActivityReport.asObservable();
  }

  getElComActivityReport(): Observable<ActivityReportModel[]> {
    return this.elcomActivityReport.asObservable();
  }

  loadRemitActivityReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.activityReportApi + orgGroupId).subscribe(
        response => {
          this.remitActivityReport.next(response.reports);
        });
    }
  }

  loadElComActivityReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.activityReportApi + orgGroupId, { reportingTo: "elcom" }).subscribe(
        response => {
          this.elcomActivityReport.next(response.reports);
        });
    }
  }

  downloadActivityReport(activityReportId, reportsFor?: 'elcom' | null) {
    const orgGroupId = this.orgGroup.id;

    if (activityReportId && orgGroupId) {
      let params = new HttpParams().append('activityReportId', activityReportId.toString());

      if (reportsFor == 'elcom') {
        params = params.append('reportingTo', 'elcom');
      }
      const url = this.activityReportApi + orgGroupId + '/download';
      return this.apiRequestService.getBlob(url, null, params).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  downloadBillingReport(dateRangeModel: DateRangeFilter) {

    const orgGroupId = this.orgGroup.id;

    if (orgGroupId) {
      const url = '/api/reporting/err/billing/' + orgGroupId + '/excel';

      return this.apiRequestService.getBlob(url, dateRangeModel, null).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  getRemitReconciliationReport(): Observable<RemitReconciliationReportModel[]> {
    return this.remitReconciliationReport.asObservable();
  }

  loadRemitReconciliationReport(orgGroupId: number) {
    if (orgGroupId) {
      this.apiRequestService.get(this.remitReconciliationReportApi + orgGroupId, null).subscribe(
        response => {
          this.remitReconciliationReport.next(response.rows);
        }
      );
    }
  }



  downloadExcelReconciliationReport(reconciliationReportId) {

    const orgGroupId = this.orgGroup.id;

    if (reconciliationReportId && orgGroupId) {
      const params = new HttpParams().append('reconciliationReportId', reconciliationReportId.toString());
      const url = this.remitReconciliationReportApi + orgGroupId + '/excel';

      return this.apiRequestService.getBlob(url, null, params).pipe(map((response: HttpResponse<Blob>) => {
        const filename = this.getFilenameFromHeader(response.headers);
        const blob: Blob = response.body;
        return { filename, blob };
      }), catchError(this.handleError));
    }
  }

  getRemitReconciliationRetentionRuns(): Observable<string> {

    const result = new Subject<string>();

    this.apiRequestService.get(this.remitReconciliationReportApi + 'retentionRuns', null).subscribe(httpResponse => {
      result.next(httpResponse.response.toString());
    });

    return result.asObservable();
  }

  private getFilenameFromHeader(headers): string {
    const contentDisposition = headers.get('content-disposition');
    return contentDisposition.split(';')[1].trim().split('=')[1];
  }

  private handleError(error: HttpErrorResponse) {
    console.error(error);
    const errMsg = (error.message) ? error.message : 'Server error';
    return observableThrowError(errMsg);
  }

  public setMessage(message: Message) {
    this.message.next(message);
  }

  public onMessageChange(): Observable<Message> {
    return this.message.asObservable();
  }

}
