import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OrganisationService } from '@common/organisation.service';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import * as fromTableSelectors from '../../state/settings/tolerance/tolerance-settings.selectors';
import { PagingFilter, SortingFilter } from '@common/shared/results.model';
import { esmToleranceTableColumns, SettingsTableModel } from './tolerance.model';
import { EsmToleranceSettingsState } from '../../state/settings/tolerance/tolerance-settings.reducers';
import { delay, filter } from 'rxjs/operators';
import {
  commitRow,
  loadPresets,
  loadTableData,
  pagingAndSorting,
  triggerCheckbox,
  updateRow
} from '../../state/settings/tolerance/tolerance-settings.actions';
import isEqual from 'lodash.isequal';

@Component({
  selector: 'cms-tolerance',
  templateUrl: './tolerance.component.html',
  styleUrls: ['./tolerance.component.scss']
})
export class ToleranceComponent implements OnDestroy, OnInit {

  values: SettingsTableModel[];
  checked: boolean;
  eTemp;
  temp;

  columns = esmToleranceTableColumns;

  subscriptions$: Subscription = new Subscription();
  messages$: Observable<Message[]> = this.store$.select(fromTableSelectors.getMessages);
  loading$: Observable<boolean> = this.store$.select(fromTableSelectors.getLoading);
  paging$: Observable<PagingFilter> = this.store$.select(fromTableSelectors.getPaging);
  sorting$: Observable<SortingFilter> = this.store$.select(fromTableSelectors.getSorting);
  editable$: Observable<boolean> = this.store$.pipe(select(fromTableSelectors.hasEditRights), delay(10));
  toggle$: Observable<boolean> = this.store$.select(fromTableSelectors.isToleranceMatchAllowed);
  data$: Observable<SettingsTableModel[]> = this.store$.select(fromTableSelectors.getTableData);
  organisation$: Observable<boolean> = this.organisationService.onRefresh().pipe(filter(auth => auth));
  /*
    fLoading$: Observable<boolean> = merge(
      this.loading$.pipe(filter(x=>x)),
      this.store$.pipe(select(fromTableSelectors.getLoading), filter(x=>!x),concatMap(x => of(x).pipe(delay(1500)))));
  */

  refreshTable = () => this.store$.dispatch(loadTableData());

  constructor(private store$: Store<EsmToleranceSettingsState>,
              public organisationService: OrganisationService) {
  }

  ngOnInit() {

    this.subscriptions$.add(this.data$.subscribe(values => this.values = values));

    this.subscriptions$.add(this.toggle$.subscribe(bool => this.checked = bool));

    this.subscriptions$.add(this.organisation$.subscribe(() => {
      this.store$.dispatch(loadTableData());
      this.store$.dispatch(loadPresets());
    }));
  }

  onLazyLoad({ first, rows, sortField, sortOrder }): void {
    const currentPage = first / rows;

    if (!isEqual(this.eTemp, { currentPage, rows, sortField, sortOrder })) {
      this.eTemp = { currentPage, rows, sortField, sortOrder };

      this.store$.dispatch(pagingAndSorting({
        paging: { page: currentPage, entriesPerPage: rows },
        sorting: { sortOrder: this.order(sortOrder), columnName: sortField }
      }));

      this.refreshTable();
    }

  }

  onRowEditInit({ data, index }) {
    this.temp = { row: { ...data }, index };
  }

  onRowEditCancel() {
    this.store$.dispatch(updateRow({ data: this.temp.row }));
    this.temp = null;
  }

  onEditComplete({ field, data }) {
    if (!isEqual(data, this.temp.row)) {

      data.absTolerance = Number(data.absTolerance);
      data.prectTolerance = Number(data.prectTolerance);

      this.store$.dispatch(commitRow({ field, data }));
    }
    this.temp = null;
  }

  toggle({ checked }) {
    this.store$.dispatch(triggerCheckbox({ checked }));
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe();
  }

  // ======  helper ====== //

  order = (sortOrder) => sortOrder === 1 ? 'ASCENDING' : 'DESCENDING';
  isDigit = (value1, value2) => (Number(value1) === 0 || Number(value1)) && (Number(value2) === 0 || Number(value2));
}
