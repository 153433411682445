import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthorisationGuard } from './auth/authorisation.guard';
import { PasswordGuard } from './login/password/password.guard';
import { LoginGuard } from './login/login.guard';
import { PermissionsComponent } from './err/robo/permission/permissions.component';
import { SmtDashboardComponent } from './err/smt/dashboard/smt-dashboard.component';
import { SmtDashboardDataViewComponent } from './err/smt/dashboard/smt-dashboard-dataview-component';
import { SmtDealFinderComponent } from './err/smt/dealfinder/smt-dealfinder.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/password/forgot-password.component';
import { ResetPasswordComponent } from './login/password/reset-password.component';
import { ChangePasswordComponent } from './preferences/change-password/change-password.component';
import { EmptyComponent } from './empty.component';
import { PageComponent } from './page.component';
import { WelcomeComponent } from './help/welcome/welcome.component';
import { SupportComponent } from './help/support/support.component';
import { ReleaseNotesComponent } from './help/release-notes/release-notes.component';
import { CpmlDealFinderComponent } from './err/cpml/dealfinder/cpml-dealfinder.component';
import { CpmlDashboardComponent } from './err/cpml/dashboard/cpml-dashboard.component';
import { CpmlDashboardPanelComponent } from './err/cpml/dashboard/cpml-dashboard-panel.component';
import { AudittrailComponent } from '@common/audittrail/audittrail.component';
import { CpmlDealviewComponent } from './err/cpml/dashboard/cpml-dealview.component';
import { RemitReportComponent } from './err/remit/report/remit-report.component';
import { RemitActivityReportComponent } from './err/remit/report/activity/remit-activity-report.component';
import { ElComActivityReportComponent}  from './err/remit/report/elcom-activity/elcom-activity-report.component';
import { RemitReconciliationReportComponent } from './err/remit/report/reconciliation/remit-reconciliation-report.component';
import { ErrRemitSettingsComponent } from './err/remit/settings/err-remit-settings.component';
import { RemitDashboardComponent } from './err/remit/dashboard/remit-dashboard.component';
import { RemitTransactionsComponent } from './err/remit/transactions/remit-transactions.component';
import { RemitTransactionsPanelComponent } from './err/remit/transactions/remit-transactions-panel.component';
import { DatatableCustomizeSettingsComponent } from './settings/datatable/customize/datatable-customize-settings.component';
import { SmtTransactionSheetComponent } from './err/smt/smt-transaction-sheet.component';
import { RemitTransactionSheetComponent } from './err/remit/transactions/remit-transaction-sheet.component';
import { ForcePasswordGuard } from './login/password/force-password.guard';
import { RemitFilesInComponent } from './err/remit/files/in/remit-files-in.component';
import { RemitBillingReportComponent } from './err/remit/report/billing/remit-billing-report.component';
import { RemitFilesOutComponent } from './err/remit/files/out/remit-files-out.component';
import { RemitOmpWhitelistComponent } from './err/remit/omp/whitelist/remit-omp-whitelist.component';
import { TradeEntryComponent } from './err/settings/trade_entry/trade_entry.component';
import { RemitTable1TradeComponent } from './err/remit/trade/table1/remit-table1-trade.component';
import { TradeEntryCustomiseComponent } from './err/settings/trade_entry/customise/trade-entry-customise.component';
import { RoboDelegatorsComponent } from './err/robo/delegators/robo-delegators.component';
import { RemitTable1TradeCancelComponent } from './err/remit/trade/table1/cancel/remit-table1-trade-cancel.component';
import { RemitTable1TradeErrorComponent } from './err/remit/trade/table1/error/remit-table1-trade-error.component';
import { RemitTradeEntryComponent } from './err/remit/trade-entry/remit-trade-entry.component';
import { RemitTable2TradeComponent } from './err/remit/trade/table2/remit-table2-trade.component';
import { RemitTable2TradeCancelComponent } from './err/remit/trade/table2/cancel/remit-table2-trade-cancel.component';
import { RemitTable2TradeErrorComponent } from './err/remit/trade/table2/error/remit-table2-trade-error.component';
import { RemitTable1TradeModifyComponent } from './err/remit/trade/table1/modify/remit-table1-trade-modify.component';
import { ErrorMessageComponent } from '@common/errors/error-message.component';
import { NotFoundComponent } from '@common/errors/not-found/not-found.component';

import { RemitTradeTable2ModifyComponent } from './err/remit/trade/table2/modify/remit-trade-table2-modify.component';
import { ContactsComponent } from './help/contacts/contacts.component';
import { MiFidComponent } from './err/mifid/mifid.component';
import { MifidDetailsComponent } from './err/mifid/mifid-details/mifid-details.component';
import { RetryQueueComponent } from './err/retry-queue/retry-queue.component';
import { ExternalRrmComponent } from './err/external-rrm/external-rrm.component';
import { DealFinderHtmlComponent } from './ecm/deal-finder/deal-finder-html/deal-finder-html.component';
import { InvoiceFinderHtmlComponent } from './esm/invoice/finder/invoice-finder-html/invoice-finder-html.component';
import { NettingFinderHtmlComponent } from './esm/netting/finder/netting-finder-html/netting-finder-html.component';
import { RemitTransResolver } from '@common/remit-trans-resolver/remit-trans-resolver.service';
import { TerminationComponent } from './err/remit/termination/termination.component';
import { MailsReportModule } from './administration/administration-tab-panel/billing-runs/acer/mails-report/mails-report.module';
import { StandingInstructionsComponent } from './err/standing-instructions/standing-instructions.component';

const APP_ROUTES: Routes = [
  {
    path: '', component: PageComponent,
    children: [
      { path: 'help/welcome', component: WelcomeComponent, canActivate: [AuthorisationGuard] },
      { path: 'help/support', component: SupportComponent, canActivate: [AuthorisationGuard] },
      { path: 'help/release_notes', component: ReleaseNotesComponent, canActivate: [AuthorisationGuard] },
      { path: 'help/contacts', component: ContactsComponent, canActivate: [AuthorisationGuard] },
      { path: 'preferences', loadChildren: () => import('./preferences/preferences.module').then(m => m.PreferencesModule), canActivate: [AuthorisationGuard] },
      { path: 'ecm', loadChildren: () => import('./ecm/ecm.module').then(m => m.EcmModule) },
      { path: 'esm', loadChildren: () => import('./esm/esm.module').then(m => m.EsmModule) },
      { path: 'err/remit_settings', component: ErrRemitSettingsComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/remit_dashboard', component: RemitDashboardComponent, canActivate: [AuthorisationGuard] },
      {
        path: 'err/remit_transactions', component: RemitTransactionsComponent, canActivate: [AuthorisationGuard],
        children: [
          { path: 'table1', component: RemitTransactionsPanelComponent },
          { path: 'table2', component: RemitTransactionsPanelComponent },
          { path: 'table3', component: RemitTransactionsPanelComponent },
          { path: 'table4', component: RemitTransactionsPanelComponent },
          { path: 'fundamentals', component: RemitTransactionsPanelComponent }
        ]
      },
      { path: 'err/remit_early_termination', component: TerminationComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/remit_files_in', component: RemitFilesInComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/remit_files_out', component: RemitFilesOutComponent, canActivate: [AuthorisationGuard] },
      {
        path: 'err/remit_trade_entry', component: RemitTradeEntryComponent, canActivate: [AuthorisationGuard],
        children: [
          { path: 'table1', component: RemitTradeEntryComponent },
          { path: 'table2', component: RemitTradeEntryComponent }
        ]
      },
      { path: 'err/remit_omp_whitelist', component: RemitOmpWhitelistComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/robo_permissions', component: PermissionsComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/robo_whitelist', component: RoboDelegatorsComponent, canActivate: [AuthorisationGuard], resolve: {whiteList: RemitTransResolver} },
      { path: 'err/standing_instructions', component: StandingInstructionsComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/standing_instruction', component: StandingInstructionsComponent, canActivate: [AuthorisationGuard] },
      {
        path: 'err/report', component: RemitReportComponent, canActivate: [AuthorisationGuard],
        children: [
          { path: 'activity', component: RemitActivityReportComponent, canActivate: [AuthorisationGuard] },
          { path: 'elcom-activity', component: ElComActivityReportComponent, canActivate: [AuthorisationGuard] },
          { path: 'remit_reconciliation', component: RemitReconciliationReportComponent, canActivate: [AuthorisationGuard] },
          { path: 'billing', component: RemitBillingReportComponent, canActivate: [AuthorisationGuard] }
        ]
      },
      { path: 'err/smt_dashboard', component: SmtDashboardComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/smt_dashboard/deals', component: SmtDashboardDataViewComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/smt_dealfinder', component: SmtDealFinderComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/cpml_dealfinder', component: CpmlDealFinderComponent, canActivate: [AuthorisationGuard] },
      {
        path: 'err/cpml_dashboard', component: CpmlDashboardComponent, canActivate: [AuthorisationGuard],
        children: [
          { path: 'in_progress', component: CpmlDashboardPanelComponent },
          { path: 'issue', component: CpmlDashboardPanelComponent },
          { path: 'complete', component: CpmlDashboardPanelComponent },
        ]
      },
      { path: 'err/deals', component: CpmlDealviewComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/settings/trade_entry', component: TradeEntryComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/settings/trade_entry/customise', component: TradeEntryCustomiseComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/mifid', component: MiFidComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/retryqueue', component: RetryQueueComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/rrm', component: ExternalRrmComponent, canActivate: [AuthorisationGuard] },
      { path: 'settings/datatable/customize', component: DatatableCustomizeSettingsComponent, canActivate: [AuthorisationGuard] },
      { path: 'masterdata', loadChildren: () => import('./master-data/master-data.module').then(m => m.MasterDataModule) },
      {
        path: 'admin',
        loadChildren: () => import('./administration/administration.module').then(m => m.AdministrationModule),
        canActivate: [AuthorisationGuard]
      },
      { path: 'etm', loadChildren: () => import('./etm/etm.module').then(m => m.EtmModule), canActivate: [AuthorisationGuard] },
      {
        path: 'problemsolving',
        loadChildren: () => import('./problem-solving/problem-solving.module').then(m => m.ProblemSolvingModule),
        canActivate: [AuthorisationGuard]
      }
    ]
  },
  {
    path: '', component: EmptyComponent,
    children: [
      { path: 'error', component: ErrorMessageComponent /*no guard used*/ },
      { path: 'not_found', component: NotFoundComponent },
      { path: 'login', component: LoginComponent, canActivate: [LoginGuard] },
      { path: 'login/token', component: LoginComponent, canActivate: [LoginGuard] },
      {
        path: 'change_password_immediately',
        component: ChangePasswordComponent,
        canActivate: [ForcePasswordGuard],
        data: { changeImmediately: true }
      },
      { path: 'admin/billing_history', loadChildren: () => import('./administration/billing-history.module').then(m => m.BillingHistoryModule), canActivate: [AuthorisationGuard] },
      { path: 'admin/mailing_details', loadChildren: () => import('./administration/mailing-details.module').then(m => m.MailingDetailsModule), canActivate: [AuthorisationGuard] },
      { path: 'admin/mails_report', loadChildren: () => import('./administration/administration-tab-panel/billing-runs/acer/mails-report/mails-report.module').then(m => m.MailsReportModule),canActivate: [AuthorisationGuard]}, // TODO  add to authorisation app
      { path: 'forgot_password', component: ForgotPasswordComponent },
      { path: 'reset_password', component: ResetPasswordComponent, canActivate: [PasswordGuard] },
      { path: 'err/smt_transaction_sheet', component: SmtTransactionSheetComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/remit_transaction_sheet', component: RemitTransactionSheetComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/cpml_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/remit_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/remit_filesin_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] }, // auditGroupId
      { path: 'err/remit_filesout_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] }, // auditGroupId
      { path: 'etm/filesin_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'ecm/audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'esm/audittrail/invoice', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'esm/audittrail/netting', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/rrm_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/mifid_audittrail', component: AudittrailComponent, canActivate: [AuthorisationGuard] },
      { path: 'err/mifid/details', component: MifidDetailsComponent, canActivate: [AuthorisationGuard] },
      { path: 'ecm/dealfinder/confirmation_doc', component: DealFinderHtmlComponent, canActivate: [AuthorisationGuard] },
      { path: 'esm/invoicefinder/esm_document', component: InvoiceFinderHtmlComponent, canActivate: [AuthorisationGuard] },
      { path: 'esm/nettingfinder/esm_document', component: NettingFinderHtmlComponent, canActivate: [AuthorisationGuard] },
      { path: 'esm', loadChildren: () => import('./near-match/near-match.module').then(m => m.NearMatchModule) },
      { path: 'ecm', loadChildren: () => import('./near-match/near-match.module').then(m => m.NearMatchModule) },
      { path: 'esm/netting_preview', loadChildren: () => import('./esm/netting-preview/netted-amount/table/esm-ntt-preview-table.module').then(m => m.EsmNttPreviewTableModule) },

      {
        path: 'ecm',
        loadChildren: () => import('./ecm-monitoring-deals/ecm-monitoring-deals.module').then(m => m.EcmMonitoringDealsModule),
        canActivate: [AuthorisationGuard]
      },
      {
        path: 'err/remit_table1_trade', canActivate: [AuthorisationGuard],
        children: [
          { path: 'cancel', component: RemitTable1TradeCancelComponent },
          { path: 'error', component: RemitTable1TradeErrorComponent },
          { path: 'modify', component: RemitTable1TradeModifyComponent },
          { path: '', component: RemitTable1TradeComponent },
        ]
      },
      {
        path: 'err/remit_table2_trade', canActivate: [AuthorisationGuard],
        children: [
          { path: 'cancel', component: RemitTable2TradeCancelComponent },
          { path: 'error', component: RemitTable2TradeErrorComponent },
          { path: 'modify', component: RemitTradeTable2ModifyComponent },
          { path: '', component: RemitTable2TradeComponent }
        ]
      }
    ]
  },
  { path: 'err/settings', redirectTo: 'err/settings/trade_entry', pathMatch: 'full' },
  { path: 'err/remit_report', redirectTo: 'err/remit_report/activity', pathMatch: 'full' },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: '**', redirectTo: 'login', pathMatch: 'full' }

];

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      bindToComponentInputs: true
    })
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutesModule { }
