<div class="datatable-container">
  <p-table
    #primeTable
    id="{{tableId}}"
    [dataKey]="dataKey"
    [rowTrackBy]="rowTrackBy"
    [value]="data"
    [first]="first"
    [rows]="rows"
    [customSort]="customSort"
    [totalRecords]="totalRecords"
    [rowsPerPageOptions]="rowsPerPageOptions"
    [sortField]="innerSortField"
    [lazy]="lazy"
    [sortOrder]="innerSortOrder"
    (onLazyLoad)="onLazyLoad($event)"
    [scrollable]="scrollable"
    [paginator]="paginator && recordsFound"
    [(selection)]="selected"
    [selectionMode]="selectionMode"
    [autoLayout]="autoLayout"
    [styleClass]="styleClass"
    [loading]="loading"
    [hidden]="hidden"
    [lazyLoadOnInit]="lazyLoadOnInit"
    (onPage)="onPage($event)"
    (selectionChange)="onSelectionChange()"
    (sortFunction)="onCustomSort($event)"
    (onSort)="onSort($event)">
    <ng-template *ngIf="captionTemplate" pTemplate="caption">
      <ng-template *ngTemplateOutlet="captionTemplate"></ng-template>
    </ng-template>
    <ng-template pTemplate="header">
      <ng-template *ngTemplateOutlet="customHeaderTemplate;"></ng-template>
      <tr *ngIf="!customHeaderTemplate">
        <th style="width: 30px" *ngIf="checkboxRow">
          <p-tableHeaderCheckbox id="table-check-header" #tableHeaderCheckbox (click)="workAroundFunction(tableHeaderCheckbox)">
          </p-tableHeaderCheckbox>
        </th>
        <ng-container *ngFor="let col of tableColumns">
          <th *ngIf="col.visible"
              [pSortableColumn]="col.sortable ? col.name : ''"
              [pSortableColumnDisabled]="!col.sortable"
              [ngStyle]="col.style" [style.padding]="'6px'">
            {{col.title}}
            <p-sortIcon [field]="col.name" *ngIf="col.sortable"></p-sortIcon>
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-rowIndex="rowIndex" let-columns="columns">
      <ng-template *ngTemplateOutlet="customBodyTemplate; context: { row: row, columns:columns, rowIndex: rowIndex}"></ng-template>
      <tr [pSelectableRow]="row" *ngIf="!customBodyTemplate">
        <td *ngIf="checkboxRow" style="width: 30px">
          <p-tableCheckbox [value]="row"></p-tableCheckbox>
        </td>
        <ng-container *ngFor="let col of tableColumns;trackBy: columnTrackBy">
          <td *ngIf="col.type === 'text'" [ngStyle]="col.style" [pTooltip]="row[col.name]"  #cbdy tooltipPosition="top" [tooltipDisabled]="widthFit(row[col.name], cbdy)">
            <span>{{row[col.name]}}</span>
          </td>
          <td *ngIf="col.type === 'date'" [ngStyle]="col.style">
            <span>{{row[col.name] | dateFormatter}}</span>
          </td>
          <td *ngIf="col.type === 'dateWithoutTime'" [ngStyle]="col.style">
            <span>{{row[col.name] | date:'dd/MM/yyyy' }}</span>
          </td>
          <td *ngIf="col.type === 'yes/no'" [ngStyle]="col.style">
            <span [ngClass]="{'green-icon': row[col.name] === 'true', 'red-icon': row[col.name] === 'false'}">
              <i class="yes-no-icons fa fa-check-circle"></i>
              <i class="yes-no-icons fa fa-minus-circle"></i>
              {{row[col.name] | yesNo}}
            </span>
          </td>
          <td *ngIf="col.type === 'currency'" [ngStyle]="col.style">
            <span>{{row[col.name] | number:'1.2-15':'en-US'}}</span>
          </td>
          <td *ngIf="col.type === 'percent'" [ngStyle]="col.style">
            <span>{{row[col.name] ? row[col.name] + '%' : ''}}</span>
          </td>
          <ng-template *ngTemplateOutlet="columnsTemplate;context: { column: col, row: row, rowIndex: rowIndex}"></ng-template>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage" >
      <ng-template *ngTemplateOutlet="customEmptyMessageTemplate;"></ng-template>
    </ng-template>
    <ng-template pTemplate="paginatorleft" *ngIf="paginator">
      <span class="paginator-info">Showing {{pagerInfo.fromNumberOfResults}} to {{pagerInfo.toNumberOfResults}} of {{pagerInfo.numberOfAllRecords}}</span>
    </ng-template>
    <ng-template pTemplate="footer" let-col>
      <ng-template *ngTemplateOutlet="footerTemplate;context: { column: col }"></ng-template>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-rowData let-columns="columns">
      <ng-template *ngTemplateOutlet="footerTemplate;context: { columns: columns, rowData: rowData }"></ng-template>
    </ng-template>
  </p-table>
  <!--<div class="p-datatable-emptymessage no-records-label" *ngIf="paginator && !hidden && (!data || !data.length)">No records found</div>-->
</div>
