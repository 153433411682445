import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BillingTxItem } from '../billing-tx-report.models';
import { select, Store } from '@ngrx/store';
import * as fromSelectors from '../../../state/billing-tx-report/billing-tx-report.selectors';
import { BillingTxReportState } from '../../../state/billing-tx-report/billing-tx-report.reducers';
import { Column, DatatableConfig } from '@common/shared/datatable.model';
import * as moment from 'moment';
import * as fromActions from '../../../state/billing-tx-report/billing-tx-report.actions';
import { SortEvent } from 'primeng/api';

@Component({
  selector: 'cms-billing-tx-datatable',
  templateUrl: './billing-tx-datatable.component.html',
  styleUrls: ['./billing-tx-datatable.component.scss']
})
export class BillingTxDatatableComponent implements OnInit {
  private tableColumns: Column[] = BillingTxReportDatatableConfig.TABLE_COLUMNS;
  defaults = DatatableConfig;
  records: any[] = [];
  map: Map<string, string> = new Map<string, string>();

  loading: boolean = false;
  billingReportsList$: Observable<BillingTxItem[]>;

  constructor(
    private store: Store<BillingTxReportState>,
  ) {
    this.map.set('executionTime', 'unix')
        .set('displayName', 'displayName')
        .set('billingQuarter', 'billingSortValue')
        .set('execTime', 'execTime')
  }

  ngOnInit(): void {
    this.billingReportsList$ = this.store.pipe(select(fromSelectors.getBillingReportList));
    this.loading = true;
    this.loadData();
    this.billingReportsList$.subscribe((list) => {
      if (list != null) {
        this.records = list.map((el: BillingTxItem) => {
          const started = moment(el.started);
          return {
            'id': el.id,
            'executionTime': started.format('DD/MM/YYYY HH:mm:ss'),
            'unix': started.unix(),
            'displayName': el.displayName,
            'billingQuarter': `Q${el.quarter} - ${el.year + 1}`,
            'billingSortValue': el.year * 4 + el.quarter,
            'execTime': el.finished && el.started ? moment.duration(moment(el.finished).diff(started)).seconds() : '',
            'reportGenerationFinished': el.finished != undefined && el.started != undefined,
          };
        })
      }

      this.loading = false;
    });

    this.store.pipe(select(fromSelectors.getReportGenerateRequest)).subscribe((reportGenerated: boolean) => {
      if (reportGenerated) {
        this.loadData();
      }
    })
  }

  loadData() {
    this.store.dispatch(fromActions.listBillingReports());
  }

  downloadBillingTxReport(id: number) {
    this.store.dispatch(fromActions.downloadBillingReport({ reportId: id }));
  }

  sort(event: SortEvent) {
    if (this.map.has(event.field)) {
      event.data.sort((a, b) => {
        if (event.order === -1) {
          return a[this.map.get(event.field)] < b[this.map.get(event.field)] ? 1 : -1
        }
        return a[this.map.get(event.field)] > b[this.map.get(event.field)] ? 1 : -1;
      });
    }
  }

  get cols() {
    return this.tableColumns;
  }
}

export namespace BillingTxReportDatatableConfig {
  export const TABLE_COLUMNS: Column[] = [
    { name: 'executionTime', title: 'Executed', style: { 'width': '150px' }, sortable: true, type: 'date' },
    { name: 'displayName', title: 'Organisation Group', style: { 'min-width': '150px', 'max-width': '250px' }, sortable: true },
    { name: 'billingQuarter', title: 'Billing Quarter', style: { 'width': '150px' }, sortable: true },
    { name: 'execTime', title: 'Exec. Time (s)', style: { 'width': '150px' }, sortable: true },
    { name: 'download', title: 'Download', style: { 'width': '130px', 'text-align': 'center'}, sortable: false }
  ];
}
