import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { Message } from 'primeng/api';
import { DatatableData, PagingFilter, SortingFilter } from '@common/shared/results.model';
import { Column } from '@common/shared/datatable.model';
import { OrganisationService } from '@common/organisation.service';
import { DatatableState } from '@common/datatable/datatable.model';
import { MessagesMapperService } from '@common/messages-mapper.service';
import { UserAccount, UserAccountsFilters } from './user-accounts.model';
import { UserAccountsState } from '../../state/user-account/user-account.reducer';
import { SetToDefaultAction } from '../../state/shared/shared.actions';
import { Prefix } from '../../state/reducers';
import { CloseDialogAction, OpenDialogAction } from '../../state/dialog/dialog.actions';
import { LoadOrganisationGroupsAction } from '../../state/organisation-group/organisation-group.actions';
import { DialogState } from '../../state/dialog/dialog.reducer';
import { SetOrganisationIdAction } from '../../state/organisation-id/organisation-id.actions';
import { OrganisationGroup } from '../administration.model';
import { EditableState } from '../common/editable-status-cell/editable-status-cell.component';
import { ReactivateUser } from '../common/dialogs-container/activate-dialog/activate-dialog.component';
import { LockInactivateUser } from '../common/dialogs-container/lock-inactivate-dialog/lock-inactivate-dialog.component';
import { AddUserAccount } from '../common/dialogs-container/add-user-account-dialog/add-user-account-dialog.component';
import { EditUserAccount } from '../common/dialogs-container/edit-user-account-dialog/edit-user-account-dialog.component';
import { ResetPassword } from '../common/dialogs-container/reset-password-dialog/reset-password-dialog.component';
import * as fromSelectors from '../../state/user-account/user-account.selectors';
import * as fromActions from '../../state/user-account/user-account.actions';
import * as fromDtActions from '../../state/datatable/datatable.actions';
import { ActivatedRoute, Router } from '@angular/router';

const defaultStyles = {
  'text-align': 'left',
  'text-overflow': 'ellipsis',
  'white-space': 'nowrap'
};

const stateStyle = { 'padding-top': '0px', 'padding-bottom': '0px', ...defaultStyles, 'text-align': 'center' };

@Component({
  selector: 'cms-user-accounts',
  templateUrl: './user-accounts.component.html',
  styleUrls: ['./user-accounts.component.scss']
})
export class UserAccountsComponent implements OnInit, OnDestroy {
  data$: Observable<DatatableData<UserAccount[]>>;
  loading$: Observable<boolean>;
  sorting$: Observable<SortingFilter>;
  filters$: Observable<UserAccountsFilters>;
  orgGroups$: Observable<OrganisationGroup[]>;
  editableData$: Observable<EditableState>;
  messages$: Observable<Message[]>;
  dialog$: Observable<DialogState>;
  organisationsId$: Observable<number>;
  selected$: Observable<UserAccount>;
  paging$: Observable<PagingFilter>;
  visibleSubscription$: Subscription;
  querySubscription$: Subscription;
  orgGroupSelectorVisible = false;
  filterInactiveVisible = true;

  readonly dataTableColumns: Column[] = [
    { name: 'organisationGroupName', type: 'text', title: 'Org. Group', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'userName', type: 'text', title: 'User Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'realName', type: 'text', title: 'Real Name', style: { ...defaultStyles }, sortable: true, visible: true },
    { name: 'state', type: 'userState', title: 'User State', style: { ...stateStyle }, sortable: true, visible: true },
    { name: 'currentLogin', type: 'date', title: 'Last Login', style: { ...defaultStyles }, sortable: true, visible: true },
  ];
  prefix: Prefix = '[USER ACCOUNT]';

  constructor(private store: Store<UserAccountsState>,
              private mapper: MessagesMapperService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private organisationService: OrganisationService) {
    this.data$ = this.store.pipe(select(fromSelectors.getData));
    this.loading$ = this.store.pipe(select(fromSelectors.getLoading));
    this.sorting$ = this.store.pipe(select(fromSelectors.getSorting));
    this.paging$ = this.store.pipe(select(fromSelectors.getPaging));
    this.filters$ = this.store.pipe(select(fromSelectors.getFilters));
    this.orgGroups$ = this.store.pipe(select(fromSelectors.getOrgGroupsList));
    this.editableData$ = this.store.pipe(select(fromSelectors.getEditableData));
    this.messages$ = this.store.pipe(select(fromSelectors.getMessages));
    this.organisationsId$ = this.store.pipe(select(fromSelectors.getOrganisationId));
    this.selected$ = this.store.pipe(select(fromSelectors.getSelected));
    this.dialog$ = this.store.pipe(select(fromSelectors.getDialog));

    this.visibleSubscription$ = this.store.pipe(select(fromSelectors.getOrgGroupSelectorVisible))
      .subscribe((orgGroupSelectorVisible: boolean) => {
        this.orgGroupSelectorVisible = orgGroupSelectorVisible;
      });
  }

  onUserAccountSelected(userAccount: UserAccount): void {
    this.store.dispatch(new fromDtActions.SelectAction(this.prefix, userAccount));
  }

  onLazyLoad(state: DatatableState): void {
    this.store.dispatch(new fromDtActions.SortAndPageDataAction(this.prefix, {
      paging: {
        page: state.page,
        entriesPerPage: state.perPage
      },
      sorting: {
        columnName: state.sortField,
        sortOrder: state.sortOrder
      }
    }));
  }

  onFilter(obj: any): void {
    this.router.navigate([], { relativeTo: this.activatedRoute, queryParams: { ...obj, organisationGroupId: obj.organisationGroupId } });
  }

  onCancelDialog(messages: Message[]): void {
    this.store.dispatch(new fromDtActions.SetMessagesAction(this.prefix, messages));
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, null));
    this.store.dispatch(new CloseDialogAction(this.prefix));
  }

  onVisibleChange(visible: boolean): void {
    if (visible) {
      this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, null));
    }
  }

  onConfirmLock(obj: LockInactivateUser): void {
    this.store.dispatch(new fromActions.LockUserAction(obj));
  }

  onConfirmReactivate(obj: ReactivateUser): void {
    this.store.dispatch(new fromActions.ReactivateUserAction(obj));
  }

  onConfirmInactivate(obj: LockInactivateUser): void {
    this.store.dispatch(new fromActions.InactivateUserAction(obj));
  }

  onEditComplete(editableState: EditableState): void {
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, editableState));
    this.store.dispatch(new OpenDialogAction(this.prefix, {
      name: 'STATE'
    }));
  }

  onCancelEdit(): void {
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, null));
  }

  onInitEdit(editableState: EditableState): void {
    this.store.dispatch(new fromDtActions.SetEditableDataAction(this.prefix, editableState));
  }

  onResetPassword(): void {
    this.store.dispatch(new OpenDialogAction(this.prefix, {
      name: 'RESET',
    }));
  }

  onEditUserAccount(): void {
    this.store.dispatch(new OpenDialogAction(this.prefix, {
      name: 'EDIT',
    }));
  }

  onConfirmEditUserAccount(obj: EditUserAccount): void {
    this.store.dispatch(new fromActions.EditUserAccountAction(obj));
  }

  onConfirmResetPassword(obj: ResetPassword): void {
    this.store.dispatch(new fromActions.ResetPasswordAction(obj));
  }

  onConfirmAddUser(obj: AddUserAccount): void {
    this.store.dispatch(new fromActions.AddUserAccountAction(obj));
  }

  onAddUserAccount(): void {
    this.store.dispatch(new OpenDialogAction(this.prefix, {
      name: 'ADD',
    }));
  }

  ngOnInit(): void {
    this.store.dispatch(new LoadOrganisationGroupsAction(this.prefix));
    this.store.dispatch(new SetOrganisationIdAction(this.prefix, this.organisationService.getCachedOrganisationId()));
    this.initFilters();
  }

  ngOnDestroy(): void {
    this.store.dispatch(new SetToDefaultAction(this.prefix));
    this.visibleSubscription$.unsubscribe();
    this.querySubscription$.unsubscribe();
  }

  private initFilters(): void {
    this.querySubscription$ = this.activatedRoute.queryParams.subscribe(queryParams => {
      const organisationGroupId = queryParams.organisationGroupId || null;
      const filterInactive = !(queryParams.filterInactive === 'false');
      this.store.dispatch(new fromDtActions.FilterDataAction(this.prefix, { organisationGroupId, filterInactive }));
    });
  }
}
