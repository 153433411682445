import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdministrationTabPanelComponent } from './administration-tab-panel/administration-tab-panel.component';
import { BillingReportsComponent } from './administration-tab-panel/billing-reports/billing-reports.component';
import { ElcomWhitelistComponent } from './administration-tab-panel/elcom-whitelist/elcom-whitelist.component';
import { BillingRunsComponent } from './administration-tab-panel/billing-runs/billing-runs.component';
import { AuthorisationGuard } from '../auth/authorisation.guard';
import { BillingTxReportComponent } from './administration-tab-panel/billing-tx-report/billing-tx-report.component';

const routes: Routes = [
  { path: 'userAccounts', component: AdministrationTabPanelComponent, data: { panel: 'userAccounts'}},
  { path: 'organisationGroups', component: AdministrationTabPanelComponent, data: { panel: 'organisationGroups'}},
  { path: 'organisations', component: AdministrationTabPanelComponent, data: { panel: 'organisations'}},
  { path: 'userRoles', component: AdministrationTabPanelComponent, data: { panel: 'userRoles'}},
  { path: 'fhaAccounts', component: AdministrationTabPanelComponent, data: { panel: 'fhaAccounts'}},
  { path: 'billing_tx_report', component: BillingTxReportComponent },
  { path: 'billing_reports', component: BillingReportsComponent },
  { path: 'partydata', component: AdministrationTabPanelComponent, data: { panel: 'partydata'}},
  { path: 'elcomwhitelist', component: ElcomWhitelistComponent },
  {
    path: 'billing_runs', canActivate: [AuthorisationGuard],
    children: [
      { path: '', data: { panel: 'cms' }, redirectTo: 'cms', pathMatch: 'full' },
      { path: 'cms', component: BillingRunsComponent, data: { panel: 'cms', title: 'CMS Billing Runs' } },
      { path: 'acer', component: BillingRunsComponent, data: { panel: 'acer', title: 'ACER Billing Runs' }}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdministrationRoutingModule { }


