import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Amounts } from './table-toast.model';
import groupBy from 'lodash.groupby';

@Component({
  selector: 'cms-table-toast',
  templateUrl: './table-toast.component.html',
  styleUrls: ['./table-toast.component.scss'],
  providers: [MessageService]
})
export class TableToastComponent {
  private _toggle: boolean;
  result: Amounts[];

  // ========= GETTER / SETTER ========= //

  @Output() toggleChange = new EventEmitter<boolean>();

  @Input() set selected(selected: Amounts[]) {
    this.calculateBuckets(selected);
    this.onSelectOrEmpty(selected);
  }

  @Input() set toggle(toggle: boolean) {
    this.pToggle(toggle);
  }

  // ========= INIT ========= //

  constructor(private messageService: MessageService) {
  }

  // ========= State Handling ========= //

  close() {
    this.pToggle(false);
    this.toggleChange.emit(this._toggle);
  }

  private pToggle(toggle: boolean): void {

    if (toggle) {
      this._toggle = true;
      this.messageService.add({ key: 'sum', sticky: true });
    } else {
      this._toggle = false;
      this.messageService.clear('sum');
    }
  }

  // ========= Helper ========= //

  private onSelectOrEmpty(selected: Amounts[]) {
    if (selected?.length === 0) {
      this.pToggle(false);

    } else if (this._toggle === false) {
      this.pToggle(true);
    }
  }

  private calculateBuckets(selected: Amounts[]) {
    const temp: { [key: string]: Amounts[]; } = groupBy(selected, (o: Amounts) => (o.grossCur + o.vatCur));
    this.result = [];
    Object.keys(temp).forEach(key => this.result.push(this.reduce(temp, key)));
  }

  private reduce = (object, key) => object[key].reduce((a, b) =>
      ({ gross: a.gross + b.gross, grossCur: b.grossCur, vat: a.vat + b.vat, vatCur: b.vatCur, vatRat: b.vatRat }),
    { gross: 0, vat: 0, grossCur: '', vatCur: '', vatRat: 0 })
}
